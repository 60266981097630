<template>
  <div v-if="trainer" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="trainer">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('firstname')"
            label-for="firstname"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="trainer.firstname" type="text" :placeholder="$t('firstname')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('lastname')"
            label-for="lastname"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="trainer.lastname" type="text" :placeholder="$t('lastname')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('trainertype')"
            label-for="trainerType"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('state') + ' ' + $t('select')"
              v-model="trainer.trainerType"
              :options="getEnumValues('TrainerType')"
              :show-labels="true"
              :allow-empty="false"
              :taggable="true"
              :selectLabel="$t('select')"
              :selectedLabel="$t('selected')"
              :deselectLabel="$t('remove_selection')"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><b-badge>{{ $t(option) }}</b-badge></template
              >
              <template slot="option" slot-scope="{ option }">
                <b-badge>
                  {{ $t(option) }}
                </b-badge></template
              >
            </multiselect>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>{{ $t('users') }}</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('users')"
            label-for="trainerType"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              class="multiselect-users"
              v-model="trainer.users"
              :options="filteredUsers"
              track-by="number"
              v-on:search-change="onFilterUsers"
              v-on:select="onSelectUser"
              v-on:remove="onSelectUser"
              :filter="true"
              :internal-search="false"
              :close-on-select="false"
              :clear-on-select="false"
              :multiple="true"
              :show-labels="false"
              :allow-empty="true"
              :deselectLabel="$t('deselectLabel')"
              selectLabel=""
              selectedLabel=""
              :placeholder="'Benutzer ' + $t('select') + '/suchen'"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.firstname }} {{ option.lastname }} - {{ option.email }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ option.firstname }} {{ option.lastname }} - {{ option.email }}
              </template>
              <template slot="tag" slot-scope="{ option }">
                <div>{{ option.firstname }} {{ option.lastname }} - {{ option.email }}</div>
              </template>
              <template slot="noResult">
                <div>{{ $t('no_results') }}</div>
              </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="trainer"
            modelEditRoute="TrainerEditPage"
            modelListRoute="Trainers"
            modelRouteParamName="trainerNumber"
            :updateCallback="updateTrainer"
            :createCallback="createTrainer"
            :deleteCallback="deleteTrainer"
            :fetchPaginatedCallback="fetchTrainersPagainated"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'TrainerEditPage',
  components: {
    Widget,
    Breadcrumbs,
    Multiselect,
    ButtonBar,
  },
  props: {
    trainerNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      filteredUsers: [],
    };
  },
  computed: {
    ...mapGetters([
      'getEnumValues',
      'getTrainer',
      'getTrainerByNumber',
      'getEnumValues',
      'getUsers',
      'getTrainerTypes',
    ]),
    buttons() {
      return {
        showDeleteButton: this.trainer && this.trainer.number !== '_new' ? true : false,
      };
    },
    trainer() {
      return this.getTrainerByNumber(this.number);
    },

    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('trainer'), route: { name: 'Trainers' } },
        { name: this.trainer.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
  },
  methods: {
    ...mapActions([
      'fetchTrainersPagainated',
      'fetchTrainerByNumber',
      'updateTrainer',
      'createTrainer',
      'deleteTrainer',
      'fetchEnumValues',
      'initTrainer',
      'fetchEnumValues',
      'fetchUsers',
    ]),
    /**
     *
     */
    async onFilterUsers(query) {
      this.filteredUsers = [];
      this.getUsers.map((user) => {
        if (
          (user.firstname && user.firstname.toLowerCase().indexOf(query) >= 0) ||
          (user.lastname && user.lastname.toLowerCase().indexOf(query) >= 0)
        ) {
          this.filteredUsers.push(user);
        }
      });
    },
    /**
     *
     */
    async onSelectUser(user) {
      // console.log(user);
      // const { data } = user;
      // this.save(data);
    },

    /**
     *
     */
    // async save(trainer) {
    //   if (trainer.number === '_new') {
    //     await this.createTrainer(trainer);
    //   } else {
    //     await this.updateTrainer(trainer);
    //   }
    // },
  },
  async mounted() {
    await this.fetchUsers();
    await this.fetchEnumValues('TrainerType');

    if (this.trainerNumber) {
      this.number = this.trainerNumber;
      await this.fetchTrainerByNumber(this.number);
    } else {
      this.initTrainer();
      this.number = '_new';
    }

    this.filteredUsers = this.getUsers;
  },
};
</script>

<style scoped lang="scss"></style>
